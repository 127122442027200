import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { SitesComponent } from './sites/sites.component';
import { SiteService } from '../shared/services/site.service';
import { Site } from '../shared/models/site';

@Injectable({ providedIn: 'root' })
export class SitesResolver implements Resolve<Site[]> {
  constructor(private service: SiteService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Site[]> {
    return this.service.query();
  }
}

const routes: Routes = [
  // { path: 'sites', loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule) },
  {
    path: 'sites/:action',
    resolve: { sites: SitesResolver },
    component: SitesComponent,
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [SitesResolver]
})
export class SitesRoutingModule { }
