import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
// import { Site } from '../shared/models/site';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private signInUrl = '/api/auth/sign-in';
  private signOutUrl = '/api/auth/sign-out';

  constructor(private http: HttpClient) { }

  signIn(params): Observable<any> {
    return this.http.post<any>(this.signInUrl, params)
      .pipe(catchError(this.handleError));
  }

  signOut(): Observable<any> {
    return this.http.post<any>(this.signOutUrl, {})
      .pipe(catchError(this.handleError));
  }

  private handleError(res: HttpErrorResponse) {
    return throwError({ message: res.error && res.error.message || res.message  });
  }
}
