<form [formGroup]="userForm" (ngSubmit)="save()" novalidate autocomplete="off">
  <fieldset [disabled]="spin" [ngClass]="{ dim: spin, spin2: spin }">
    <div class="ff-row">
      <label class="ff-col ff-cell">
        <span class="ff-label" *ngIf="!validation?.fullName">Full Name</span>
        <span class="ff-label text-warn" *ngIf="validation?.fullName">{{validation?.fullName}}</span>
        <input type="text" name="fullName" formControlName="fullName" class="ff-input">
      </label>
    </div>

    <div class="ff-row">
      <label class="ff-col ff-cell">
        <span class="ff-label" *ngIf="!validation?.email">Email</span>
        <span class="ff-label text-warn" *ngIf="validation?.email">{{validation?.email}}</span>
        <input type="text" name="email" formControlName="email" class="ff-input">
      </label>

      <label class="ff-col ff-cell">
        <span class="ff-label" *ngIf="!validation?.password">password</span>
        <span class="ff-label text-warn" *ngIf="validation?.password">{{validation?.password}}</span>
        <input type="password" name="password" formControlName="password" class="ff-input">
      </label>
    </div>

    <div>
      <button type="submit" class="ff-btn text-info" title="Save"><i class="fa fa-check"></i></button>
      <button class="ff-btn" [mat-dialog-close] title="Discard"><i class="fa fa-ban"></i></button>
    </div>

    <div *ngIf="error" class="ff-label text-warn ff-cell">{{error}}</div>
  </fieldset>
</form>
