<dw-navbar></dw-navbar>

<dw-sites-filter></dw-sites-filter>

<ul class="main-list">
  <li *ngFor="let site of filteredSites$ | async" class="ml-item">
    <div>
      <div class="ml-header">

        <div class="ml-cell ml-sidebar">
          <span class="ml-icon">
            <img [src]="site.url + '/api/v1/white-label/images/icon-96x96.png'" *ngIf="site.url" dwErrSrc="{{site.url}}/assets/favicon.png">
          </span>
          <span class="ml-name">{{site.name}}</span>
        </div>

        <div [ngSwitch]="action" class="ml-cell ml-bar">
          <dw-site-health *ngSwitchCase="'health'" [site]="site"></dw-site-health>
          <dw-site-onboarding *ngSwitchCase="'onboarding'" [site]="site"></dw-site-onboarding>
          <dw-site-centers *ngSwitchCase="'centers'" [site]="site"></dw-site-centers>
          <dw-site-edit *ngSwitchCase="'edit'" [site]="site" (deleted)="onDeleted($event)"></dw-site-edit>
        </div>

      </div>
    </div>
  </li>
</ul>

<div class="ff-row ml-header" *ngIf="action === 'edit'">
  <button class="ff-btn text-info ff-pull-right" title="New Site" (click)="create()"><i class="fa fa-plus"></i></button>
</div>
