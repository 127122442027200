import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { map } from 'rxjs/operators';

import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private matDialog: MatDialog) { }

  public confirm(text: string): Promise<boolean> {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      width: '320px',
      data: { text },
    });

    return dialogRef.beforeClosed().pipe(map(s => !!s)).toPromise();
  }
}
