import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toDate'
})
export class ToDatePipe implements PipeTransform {

  private datePattern = /^\d\d\d\d-\d\d-\d\d(?:T\d\d:\d\d:\d\d(?:(?:\.\d\d\d\d?)?(?:(?:-|\+)\d\d:?\d\d)?Z?)?)?$/i;
  // return angular.isString(dateString) && (
  //   !!(dateString.match(/^\d\d\d\d-\d\d-\d\d(?:T\d\d:\d\d:\d\d(?:(?:(?:-|\+)\d\d:?\d\d)|(?:\.\d\d\d\d?)?Z)?)?$/i) ||
  //     // !!(dateString.match(/^\d\d\d\d-\d\d-\d\d(?:T\d\d:\d\d:\d\d(?:(?:-|\+)\d\d:?\d\d)?)?$/i) ||
  //     dateString.match(/^\d\d:\d\d(?::?\d\d)?$/i))

  public transform(value: any): any {
    if (typeof value !== 'string' || value.length < 10 || value.length > 32 || !value.match(this.datePattern)) { return value; }
    return new Date(value);
  }

}
