<form [formGroup]="siteForm" (ngSubmit)="save()" class="sign-in" novalidate autocomplete="off">
  <fieldset [disabled]="spin" [ngClass]="{ dim: spin, spin2: spin }">
    <div class="ff-row">
      <label class="ff-col ff-cell">
        <span class="ff-label" *ngIf="!validation?.name">Name</span>
        <span class="ff-label text-warn" *ngIf="validation?.name">{{validation?.name}}</span>
        <input type="text" name="name" formControlName="name" class="ff-input">
      </label>

      <label class="ff-col ff-cell">
        <span class="ff-label" *ngIf="!validation?.url">URL</span>
        <span class="ff-label text-warn" *ngIf="validation?.url">{{validation?.url}}</span>
        <input type="text" name="url" formControlName="url" class="ff-input">
      </label>
    </div>

    <div class="ff-row">
      <label class="ff-col ff-cell">
        <span class="ff-label" *ngIf="!validation?.login">Login</span>
        <span class="ff-label text-warn" *ngIf="validation?.login">{{validation?.login}}</span>
        <input type="text" name="login" formControlName="login" class="ff-input">
      </label>

      <label class="ff-col ff-cell">
        <span class="ff-label" *ngIf="!validation?.password">password</span>
        <span class="ff-label text-warn" *ngIf="validation?.password">{{validation?.password}}</span>
        <input type="password" name="password" formControlName="password" class="ff-input">
      </label>
    </div>

    <div>
      <button type="submit" class="ff-btn text-info" title="Save"><i class="fa fa-check"></i></button>
      <button class="ff-btn" [mat-dialog-close] title="Discard"><i class="fa fa-ban"></i></button>
    </div>

    <div *ngIf="error" class="ff-label text-warn ff-cell">{{error}}</div>
  </fieldset>
</form>
