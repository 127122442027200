import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { UsersComponent } from './users/users.component';
import { UserService } from '../shared/services/user.service';
import { User } from '../shared/models/user';

@Injectable({ providedIn: 'root' })
export class UsersResolver implements Resolve<User[]> {
  constructor(private service: UserService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<User[]> {
    return this.service.query();
  }
}

const routes: Routes = [
  {
    path: 'users',
    resolve: { users: UsersResolver },
    component: UsersComponent,
    runGuardsAndResolvers: 'always',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [UsersResolver]
})
export class UsersRoutingModule { }
