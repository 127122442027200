<form [formGroup]="filterForm">
  <label class="ff-cell">
    <span class="ff-label">Order By</span>
    <select formControlName="order" class="ff-input">
      <option *ngFor="let opt of orderOptions" [ngValue]="opt.id">{{opt.name}}</option>
    </select>
  </label>
  <label class="ff-cell">
    <span class="ff-label">Reverse</span>
    <input type="checkbox" formControlName="reverse" class="ff-input">
  </label>
  <label class="ff-cell">
    <span class="ff-label">Hide Sandboxes</span>
    <input type="checkbox" formControlName="hideSandboxes" class="ff-input">
  </label>
</form>
