import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";

import { AuthService } from "../services/auth.service";

@Component({
  selector: 'dw-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit() {
  }

  public onSignOutClick() {
    this.authService.signOut()
      .subscribe(() => this.router.navigate(['/sign-in']));
  }
}
