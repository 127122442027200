import { Component, OnInit, HostBinding } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Site } from '../../shared/models/site';
import { SitesFilterService } from '../sites-filter/sites-filter.service';
import { SiteEditModalComponent } from '../site-edit-modal/site-edit-modal.component';

@Component({
  selector: 'dw-sites',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.scss']
})
export class SitesComponent implements OnInit {
  @HostBinding('class') public hostClass: string;
  public action: string;
  private sites: Site[];
  public filteredSites$: Observable<Site[]>;

  constructor(private router: Router, private route: ActivatedRoute, private matDialog: MatDialog,
              private sitesFilter: SitesFilterService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false; // force component re-instantiating on route param change
    this.action = route.snapshot.params.action;
    this.hostClass = this.action;
    this.sites = route.snapshot.data.sites;
  }

  ngOnInit() {
    this.filteredSites$ = this.sitesFilter.predicate$
      .pipe(map(predicate => this.sitesFilter.filter(this.sites, predicate)));
  }

  public onDeleted(site) {
    const idx = this.sites.indexOf(site);
    this.sites.splice(idx, 1);
  }

  public create() {
    const dialogRef = this.matDialog.open(SiteEditModalComponent, {
      width: '418px',
      data: {},
    });

    dialogRef.beforeClosed()
      .subscribe(site => {
        if (site) {
          this.sites.push(site);
        }
      });
  }
}
