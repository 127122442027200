<div class="ff-col ff-cell" style="flex: 0 0 70px">
  <span class="ff-label">Installed</span>
  <span class="ff-stat">
    <span>{{firstPollDateCount || 'No'}}</span>
    <small *ngIf="firstPollDateCount"> {{firstPollDateUnit}}</small>
  </span>
</div>
<div class="ff-col ff-cell" style="flex: 0 0 36px">
  <span class="ff-label">Sweep</span>
  <span class="ff-stat">{{site.sweep | onOff}}</span>
</div>
<div class="ff-col ff-cell" style="flex: 0 0 52px">
  <span class="ff-label">Payment</span>
  <span class="ff-stat">{{site.paymentGateway | onOff}}</span>
</div>
<div class="ff-col ff-cell">
  <span class="ff-label">Centers</span>
  <span class="ff-stat">{{site.centersCount || '-'}}</span>
</div>
<div class="ff-col ff-cell">
  <span class="ff-label">Products</span>
  <span class="ff-stat">{{site.productsCount || '-'}}</span>
</div>
<div class="ff-col ff-cell">
  <span class="ff-label">PriceLists</span>
  <span class="ff-stat">{{site.priceListsCount || '-'}}</span>
</div>
<div class="ff-col ff-cell">
  <span class="ff-label">Res. Units</span>
  <span class="ff-stat">{{site.reservationUnitsCount || '-'}}</span>
</div>
<div class="ff-col ff-cell">
  <span class="ff-label">Members</span>
  <span class="ff-stat">{{site.activeMembersCount || '-'}}</span>
</div>
<div class="ff-col ff-cell">
  <span class="ff-label">Non-Members</span>
  <span class="ff-stat">{{site.nonMembersCount || '-'}}</span>
</div>
<div class="ff-col ff-cell">
  <span class="ff-label" title="Sign-In Traffic">S-In Tr</span>
  <span class="ff-stat">{{site.traffic || '-'}}</span>
</div>
<div class="ff-col ff-cell">
  <span class="ff-label" title="Reservation Traffic">Res Tr</span>
  <span class="ff-stat">{{site.reservationsTraffic || '-'}}</span>
</div>
<div class="ff-col ff-cell">
  <span class="ff-label" title="Check-In Traffic">Ch-In Tr</span>
  <span class="ff-stat">{{site.checkInsTraffic || '-'}}</span>
</div>
<div class="ff-col ff-cell">
  <span class="ff-label">Q.Books</span>
  <span class="ff-stat">{{site.qboIntegration | onOff}}</span>
</div>
<div class="ff-col ff-cell">
  <span class="ff-label">Calendar</span>
  <span class="ff-stat">{{site.calIntegration | onOff}}</span>
</div>
