import { Component, Input, OnInit } from '@angular/core';
import { Site } from '../../shared/models/site';

@Component({
  selector: 'dw-site-centers',
  templateUrl: './site-centers.component.html',
  styleUrls: ['./site-centers.component.scss']
})
export class SiteCentersComponent implements OnInit {
  @Input() public site: Site;

  constructor() { }

  ngOnInit() {
  }

}
