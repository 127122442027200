<div class="ff-col ff-cell" style="flex: 1 0 100px">
  <span class="ff-label">Site Health</span>
  <span class="ff-stat" style="text-align: left" *ngIf="site.lastPollDate; else empty">
    <i class="fa fa-check-circle text-info"></i>
    <small>{{ site.lastPollDate | timespan }} ago</small>
  </span>
</div>

<div class="ff-col ff-cell" style="flex: 1 0 100px">
  <span class="ff-label">Mail Health</span>
  <span class="ff-stat" style="text-align: left" *ngIf="site.lastMailDate; else empty">
    <i class="fa fa-check-circle text-info"></i>
    <small>{{ site.lastMailDate | timespan }} ago</small>
  </span>
</div>

<div class="ff-col ff-cell" style="flex: 10 0 150px">
  <span class="ff-label">Sweep Health</span>
  <span class="ff-stat" style="text-align: left" *ngIf="site.lastSweepDate; else empty">
    <i class="fa fa-check-circle text-info"></i>
    <small>{{ site.lastSweepDate | timespan }} ago / {{ site.lastSweepAmount | currency }}</small>
  </span>
</div>

<ng-template #empty>
  <span class="ff-stat" style="text-align: left">
    <small>N/A</small>
  </span>
</ng-template>
