import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material';
import { finalize } from 'rxjs/operators';

import { Site } from '../../shared/models/site';
import { SiteService } from '../../shared/services/site.service';
import { ModalService } from '../../shared/modal/modal.service';
import { SiteEditModalComponent } from '../site-edit-modal/site-edit-modal.component';

@Component({
  selector: 'dw-site-edit',
  templateUrl: './site-edit.component.html',
  styleUrls: ['./site-edit.component.scss']
})
export class SiteEditComponent implements OnInit {
  @Input() public site: Site;
  @Output() deleted = new EventEmitter<Site>();

  error: string;
  deleteSpin = false;

  constructor(private modalService: ModalService, private siteService: SiteService, private matDialog: MatDialog) { }

  ngOnInit() {
  }

  public edit(site) {
    const dialogRef = this.matDialog.open(SiteEditModalComponent, {
      width: '418px',
      data: this.site,
    });
  }

  public async delete(site) {
    this.error = null;

    if (!site._id) {
      this.deleted.emit(site);
      return;
    }

    if (await this.modalService.confirm('Are you sure to remove the site?')) {
      this.deleteSpin = true;
      this.siteService.delete(this.site._id)
        .pipe(finalize(() => this.deleteSpin = false))
        .subscribe(res => this.deleted.emit(site), err => this.error = err.error.message || 'Failed to remove site');
    }
  }

}
