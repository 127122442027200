import { Directive, Input, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: 'img[dwErrSrc]',
})
export class ErrSrcDirective {
  @Input() private dwErrSrc: string;

  private applied = false;

  constructor(private el: ElementRef) {}

  @HostListener('error')
  public onError(): void {
    if (this.applied) { return; } // prevent loop of loading failed fallback image
    this.applied = true;
    const img: HTMLImageElement = this.el.nativeElement;
    img.src = this.dwErrSrc;
  }
}
