<div class="ff-row" style="flex: 100%" *ngIf="site.centers.length">
  <div class="ff-col ff-cell single-label" style="flex: 0 1 220px">
    <span class="ff-label">Center</span>
  </div>
  <div class="ff-col ff-cell single-label" style="flex: 0 1 80px">
    <span class="ff-label text-center">Members + Co-Members</span>
  </div>
</div>

<div class="ff-row" style="flex: 100%" *ngFor="let center of site.centers">
  <div class="ff-col ff-cell" style="flex: 0 0 220px">
    <span class="single-value">
      <span>{{center.name}}</span>
    </span>
  </div>
  <div class="ff-col ff-cell" style="flex: 0 0 80px; justify-content: center;">
    <span class="ff-stat">
      <span>{{center.activeMembersCount}}</span>
    </span>
  </div>
</div>
