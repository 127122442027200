import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';

import { ErrSrcDirective } from './directives/err-src.directive';
import { TimespanPipe } from './pipes/timespan.pipe';
import { ToDatePipe } from './pipes/to-date.pipe';
import { DatesSerializationInterceptor } from './interceptors/dates-serialization.interceptor';
import { OnOffPipe } from './pipes/on-off.pipe';
import { ConfirmModalComponent } from './modal/confirm-modal/confirm-modal.component';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    RouterModule.forChild([]),
  ],
  entryComponents: [ConfirmModalComponent],
  declarations: [ErrSrcDirective, TimespanPipe, ToDatePipe, OnOffPipe, ConfirmModalComponent, NavbarComponent],
  exports: [ErrSrcDirective, TimespanPipe, ToDatePipe, OnOffPipe, NavbarComponent],
  providers: [
    ToDatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: DatesSerializationInterceptor, multi: true },
  ],
})
export class SharedModule { }
