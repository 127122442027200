import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ToDatePipe } from '../pipes/to-date.pipe';

/**
 * Serialize/deserialize dates in HTTP requests/responses
 */
@Injectable({
  providedIn: 'root'
})
export class DatesSerializationInterceptor implements HttpInterceptor {

  constructor(private toDate: ToDatePipe) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(filter(res => res instanceof HttpResponse && (Array.isArray(res.body) || typeof res.body === 'object')))
      .pipe(map((res: HttpResponse<any>) => res.clone({ body: this.deserializeDates(res.body) })));
  }

  deserializeDates(body: any): any {
    for (const i in body) {
      if (body.hasOwnProperty(i)) {
        if (Array.isArray(body[i]) || typeof body[i] === 'object') {
          this.deserializeDates(body[i]); // recursion
        } else {
          body[i] = this.toDate.transform(body[i]);
        }
      }
    }
    return body;
  }

}
