<dw-navbar></dw-navbar>

<ul class="main-list">
  <li *ngFor="let user of users" class="ml-item">
    <div>
      <div class="ml-header">

        <div class="ml-cell ml-sidebar">
          <span class="ml-name">{{user.fullName}}</span>
        </div>

        <div class="ml-cell ml-bar">
          <div class="ff-col ff-cell" style="flex: 1 0 100px">
            <span *ngIf="!usersMeta[user._id]?.error" class="ff-label">Email</span>
            <span *ngIf="usersMeta[user._id]?.error" class="ff-label text-warn">{{usersMeta[user._id]?.error}}</span>
            <span class="ff-url">{{user.email}}</span>
          </div>

          <div class="ff-pull-right">
            <button class="ff-btn text-primary" (click)="edit(user)" title="Edit"><i class="fa fa-pencil"></i></button>
            <button class="ff-btn text-warn ff-pull-right" (click)="delete(user)" title="Remove"
                    [ngClass]="{ dim: usersMeta[user._id]?.spin, spin2: usersMeta[user._id]?.spin }">
              <i class="fa fa-remove"></i>
            </button>
          </div>
        </div>

      </div>
    </div>
  </li>
</ul>

<div class="ff-row ml-header">
  <button class="ff-btn text-info ff-pull-right" title="New User" (click)="create()"><i class="fa fa-plus"></i></button>
</div>
