import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { finalize } from 'rxjs/operators';

import { User } from '../../shared/models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { UserEditModalComponent } from '../user-edit-modal/user-edit-modal.component';
import { ModalService } from '../../shared/modal/modal.service';
import { UserService } from '../../shared/services/user.service';

@Component({
  selector: 'dw-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  public users: User[];
  public usersMeta = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private modalService: ModalService,
    private userService: UserService,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false; // force component re-instantiating on route param change
    this.users = this.route.snapshot.data.users;
  }

  ngOnInit() {
  }

  public create() {
    this.openEditDialog({})
      .subscribe(user => {
        if (user) {
          this.users.push(user);
        }
      });
  }

  public edit(user) {
    this.openEditDialog(user);
  }

  public async delete(user) {
    this.usersMeta[user._id] = {};

    if (!user._id) {
      return this.users.splice(this.users.indexOf(user), 1);
    }

    if (await this.modalService.confirm('Are you sure to remove the site?')) {
      this.usersMeta[user._id].spin = true;
      this.userService.delete(user._id)
        .pipe(finalize(() => this.usersMeta[user._id].spin = false))
        .subscribe(
          res => this.users.splice(this.users.indexOf(user), 1),
          err => this.usersMeta[user._id].error = err.error.message || 'Failed to remove user'
        );
    }
  }

  private openEditDialog(user) {
    const dialogRef = this.matDialog.open(UserEditModalComponent, {
      width: '418px',
      data: user,
    });

    return dialogRef.beforeClosed();
  }
}
