import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { User } from '../../shared/models/user';
import { UserService } from '../../shared/services/user.service';

@Component({
  selector: 'dw-user-edit-modal',
  templateUrl: './user-edit-modal.component.html',
  styleUrls: ['./user-edit-modal.component.scss']
})
export class UserEditModalComponent implements OnInit {
  public userForm: FormGroup;
  public validation: any;
  public error: string;
  public spin = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public user: User,
    private userService: UserService,
    private dialogRef: MatDialogRef<UserEditModalComponent>
  ) {

    this.userForm = new FormGroup({
      fullName: new FormControl(user.fullName),
      email: new FormControl(user.email),
      password: new FormControl(),
    });
  }

  ngOnInit() {
  }

  public save() {
    this.spin = true;
    this.error = null;
    this.validation = null;
    this.cdRef.detectChanges(); // prevent ExpressionChangedAfterItHasBeenCheckedError due to loosing focus after disabling fieldset

    this.userService.save({ ...this.user, ...this.userForm.value })
      .pipe(finalize(() => this.spin = false))
      .subscribe(
        user => {
          Object.keys(user).forEach(key => this.user[key] = user[key]);
          this.dialogRef.close(this.user);
        },
        err => {
          this.validation = err.error.validation;
          if (!this.validation) {
            this.error = err.error.message || 'Failed to save user';
          }
        }
      );
  }

}
