<div class="ff-col ff-cell">
  <span *ngIf="!error" class="ff-label">URL</span>
  <span *ngIf="error" class="ff-label text-warn">{{error}}</span>
  <span class="ff-url">{{site.url}}</span>
</div>
<div class="ff-pull-right">
  <button class="ff-btn text-primary" (click)="edit(site)" title="Edit"><i class="fa fa-pencil"></i></button>
  <button class="ff-btn text-warn ff-pull-right" (click)="delete(site)" title="Remove">
    <i class="fa fa-remove"></i>
  </button>
</div>
