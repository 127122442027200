import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Site } from '../models/site';
import { ApiCreateResult } from '../models/api-create-result';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  private url = 'api/sites';

  constructor(private http: HttpClient) { }

  query(): Observable<Site[]> {
    return this.http.get<Site[]>(this.url);
  }

  save(site: Site): Observable<any> { // TODO: interface for returned value
    if (!site.password) delete site.password;
    if (!site._id) {
      return this.http.post<ApiCreateResult>(this.url, site)
        .pipe(tap(res => site._id = res._id));
    }
    return this.http.put<any>(`${this.url}/${site._id}`, site);
  }

  delete(siteId: string): Observable<any> {
    return this.http.delete<any>(`${this.url}/${siteId}`);
  }
}
