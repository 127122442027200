import { Pipe, PipeTransform } from '@angular/core';

/**
 * Filters difference between dates as sec/min/hours/days
 */
@Pipe({
  name: 'timespan'
})
export class TimespanPipe implements PipeTransform {

  transform(date1: Date, date2: Date = new Date()): string {
    if (!(date1 instanceof Date)) { return date1; }
    let diff = Math.abs(date1.valueOf() - date2.valueOf());

    diff = Math.floor(diff / 1000);
    if (diff < 60) { // seconds
      return `${diff} sec`;
    }
    diff = Math.floor(diff / 60);
    if (diff < 60) { // minutes
      return `${diff} min`;
    }
    diff = Math.floor(diff / 60);
    if (diff < 60) { // hours
      return diff + (diff === 1 ? ' hour' : ' hours');
    }
    diff = Math.floor(diff / 24);
    if (diff < 30) { // days
      return diff + (diff === 1 ? ' day' : ' days');
    }
    diff = Math.floor(diff / 30);
    return diff + (diff === 1 ? ' month' : ' months');
  }

}
