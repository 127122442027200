import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService} from '../../shared/services/auth.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'dw-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  error: string;
  spin: boolean;

  signInForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });

  constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
  }

  signIn() {
    this.error = null;
    this.spin = true;

    this.auth.signIn(this.signInForm.value)
      .pipe(finalize(() => this.spin = false))
    .subscribe(() => this.router.navigate([this.route.snapshot.queryParamMap.get('redirectTo') || '/']), err => this.error = err.message);
  }
}
