import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Site } from '../../shared/models/site';
import { SitesFilterPredicate } from './sites-filter-predicate';

@Injectable({
  providedIn: 'root'
})
export class SitesFilterService {
  private predicateSubject = new BehaviorSubject<SitesFilterPredicate>({ order: 'name', reverse: false, hideSandboxes: false });

  orderOptions = [
    { id: 'name', name: 'Site Name' },
    { id: 'firstPollDate', name: 'Installed' },
    { id: 'sweep', name: 'Sweep Enabled' },
    { id: 'paymentGateway', name: 'Payment Gateway' },
    { id: 'centersCount', name: 'Centers' },
    { id: 'productsCount', name: 'Products' },
    { id: 'priceListsCount', name: 'PriceLists' },
    { id: 'reservationUnitsCount', name: 'Reservation Units' },
    { id: 'activeMembersCount', name: 'Members' },
    { id: 'nonMembersCount', name: 'Non-Members' },
    { id: 'traffic', name: 'Sign-In Traffic' },
    { id: 'reservationsTraffic', name: 'Reservation Traffic' },
    { id: 'checkInsTraffic', name: 'Check-In Traffic' },
    { id: 'qboIntegration', name: 'QuickBooks Integration' },
    { id: 'calIntegration', name: 'Calendar Integration' },
  ];

  constructor() { }

  set predicate(val: SitesFilterPredicate) {
    this.predicateSubject.next(val);
  }

  get predicate(): SitesFilterPredicate {
    return this.predicateSubject.getValue();
  }

  get predicate$() {
    return this.predicateSubject.asObservable();
  }

  filter(sites: Site[], predicate): Site[] {
    const { order, reverse, hideSandboxes } = predicate;
    if (hideSandboxes) { // hide sandboxes
      sites = sites.filter(site => !site.url || !site.url.match(/\/\/\d+\.satellitedeskworks\.com/));
    }

    if (order) { // order
      sites = sites.sort((s1, s2) => s1[order] > s2[order] ? 1 : s1[order] < s2[order] ? -1 : 0);
      if (reverse) {
        sites.reverse();
      }
    }

    return sites;
  }
}
