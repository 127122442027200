import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { SitesFilterService } from './sites-filter.service';

@Component({
  selector: 'dw-sites-filter',
  templateUrl: './sites-filter.component.html',
  styleUrls: ['./sites-filter.component.scss']
})
export class SitesFilterComponent implements OnInit, OnDestroy {
  public filterForm: FormGroup;
  public orderOptions: any[];
  private subs: Subscription[] = [];

  constructor(private sitesFilter: SitesFilterService) {
    const { predicate } = this.sitesFilter;
    this.filterForm = new FormGroup({
      order: new FormControl(predicate.order),
      reverse: new FormControl(predicate.reverse),
      hideSandboxes: new FormControl(predicate.hideSandboxes),
    });
    this.orderOptions = sitesFilter.orderOptions;
  }

  ngOnInit() {
    this.subs.push(this.filterForm.valueChanges
      .subscribe(predicate => { this.sitesFilter.predicate = predicate; }));
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
