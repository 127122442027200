<div>
  <h2 class="text-center">Deskworks Dashboard Sign In</h2>

  <form [formGroup]="signInForm" (ngSubmit)="signIn()" class="sign-in" novalidate autocomplete="off" style="max-width: 300px; margin: auto;">
    <fieldset [disabled]="spin" [ngClass]="{ dim: spin, spin2: spin }">
      <div class="ff-col">
        <label class="ff-col ff-cell">
          <span class="ff-label">Email</span>
          <input type="text" name="email" formControlName="email" class="ff-input" placeholder="email">
        </label>

        <label class="ff-col ff-cell">
          <span class="ff-label">Password</span>
          <input type="password" name="password" formControlName="password" class="ff-input" placeholder="password">
        </label>

        <div *ngIf="error" class="ff-label text-warn ff-cell">{{error}}</div>

        <div>
          <button type="submit" class="ff-btn text-info" title="Sign In"><i class="fa fa-check"></i></button>
        </div>
      </div>
    </fieldset>
  </form>
</div>
