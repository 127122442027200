import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormControl } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { Site } from '../../shared/models/site';
import { SiteService } from '../../shared/services/site.service';

@Component({
  selector: 'dw-site-edit-modal',
  templateUrl: './site-edit-modal.component.html',
  styleUrls: ['./site-edit-modal.component.scss']
})
export class SiteEditModalComponent implements OnInit {

  public siteForm: FormGroup;
  public validation: any;
  public error: string;
  public spin = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public site: Site,
    private siteService: SiteService,
    private dialogRef: MatDialogRef<SiteEditModalComponent>
  ) {

    this.siteForm = new FormGroup({
      name: new FormControl(site.name),
      url: new FormControl(site.url),
      login: new FormControl(site.login),
      password: new FormControl(),
    });
  }

  ngOnInit() {
  }

  public save() {
    this.spin = true;
    this.error = null;
    this.validation = null;
    this.cdRef.detectChanges(); // prevent ExpressionChangedAfterItHasBeenCheckedError due to loosing focus after disabling fieldset

    this.siteService.save({ ...this.site, ...this.siteForm.value })
      .pipe(finalize(() => this.spin = false))
      .subscribe(
        site => {
          Object.keys(site).forEach(key => this.site[key] = site[key]);
          this.dialogRef.close(site);
        },
        err => {
          this.validation = err.error.validation;
          if (!this.validation) {
            this.error = err.error.message || 'Failed to save site';
          }
        }
      );
  }
}
