import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material';

import { SitesRoutingModule } from './sites-routing.module';
import { SharedModule } from '../shared/shared.module';
import { SitesComponent } from './sites/sites.component';
import { SitesFilterComponent } from './sites-filter/sites-filter.component';
import { SiteHealthComponent } from './site-health/site-health.component';
import { SiteOnboardingComponent } from './site-onboarding/site-onboarding.component';
import { SiteCentersComponent } from './site-centers/site-centers.component';
import { SiteEditComponent } from './site-edit/site-edit.component';
import { SiteEditModalComponent } from './site-edit-modal/site-edit-modal.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    SitesRoutingModule,
    MatDialogModule,
  ],
  entryComponents: [SiteEditModalComponent],
  declarations: [
    SitesComponent,
    SitesFilterComponent,
    SiteHealthComponent,
    SiteOnboardingComponent,
    SiteCentersComponent,
    SiteEditComponent,
    SiteEditModalComponent
  ],
})
export class SitesModule { }
