import { Component, Input, OnInit } from '@angular/core';

import { Site } from '../../shared/models/site';
import { TimespanPipe } from '../../shared/pipes/timespan.pipe';

@Component({
  selector: 'dw-site-onboarding',
  templateUrl: './site-onboarding.component.html',
  styleUrls: ['./site-onboarding.component.scss']
})
export class SiteOnboardingComponent implements OnInit {
  @Input() public site: Site;

  private timespanPipe = new TimespanPipe();
  public firstPollDateCount: string;
  public firstPollDateUnit: string;

  constructor() {}

  ngOnInit() {
    const tspan = this.site.firstPollDate ? this.timespanPipe.transform(this.site.firstPollDate).split(' ') : '';
    this.firstPollDateCount = tspan[0];
    this.firstPollDateUnit = tspan[1];
  }

}
