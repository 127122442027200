import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  private handleError(response: HttpErrorResponse) {
    if (response.error instanceof ErrorEvent) {
      console.error('An error occurred:', response.error.message);
    } else {
      if (response.status === 401) {
        this.router.navigate(['/sign-in'], {
          queryParams: {
            redirectTo: document.location.pathname
          }
        });
      }
    }
    return throwError(response);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(catchError(err => this.handleError(err)));
  }
}
